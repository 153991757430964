@media print {
	.hidden-print {
		display: none !important;
	}
	.content-wrap {
		background: #FFF;
	}
}

.center-cropped {
	width: 150px;
	height: 150px;
	margin-right: 20px;
	margin-bottom: 20px;
	background-position: center center;
	background-repeat: no-repeat;
	background-size:cover;
	overflow: hidden;
}

/* Set the image to fill its parent and make transparent */
.center-cropped img {
	min-height: 100%;
	min-width: 100%;
	cursor: pointer;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: alpha(opacity=0);
	opacity: 0;
}

td.highlight {
	background-color: whitesmoke !important;
}

.text-white {
	color: white;
}

img.menu-icon {
	margin-bottom:0px;
	height:30px;
	width:30px;
	opacity: .5;
}

table.dataTable,
table.dataTable th,
table.dataTable td {
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
}

.table-outer {
	overflow-x: scroll !important;
	-webkit-overflow-scrolling: touch;
}
table .fixed-col {
	position:absolute;
	border:none;
	background-color:#fff;
	display:block;
	margin-left:-17px;
	padding-left:22px !important;
	width:150px;
}
.table>tbody>tr>td.fixed-col {
	border-top: none !important;
}
table .transparent {
	color:transparent !important;
}

.small-col {
	width:50px !important;
}
.medium-col {
	width:100px !important;
}
.large-col {
	width:150px !important;
}
.xl-col {
	width:250px !important;
}
.sr-force-large-col{
    width:180px !important;
    display:block;
}
th {
	border-radius:0;
}
.img-responsive {
	max-width: 100%;
	height:auto;
}
.profile-thumb {
	border-radius:50px;
	object-fit:cover;
	width:35px;
	height:35px;
}
#sign-in {
	background:#dedede !important;
}

table.noborder>tbody>tr>td {
	border:0px;
	font-size:13px;
	padding:0;
}
